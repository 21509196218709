<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>库存盘点</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form class="search-form" @submit.prevent>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label-width="120px" label="盘点单号：">
            {{ master.billCode }}
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="120" label="盘点人：">
            {{ master.createUserName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="150" label="开始盘点时间：">
            {{ master.createDate }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="150" label="盘点状态：">
            <span v-if="master.status == -1">已取消</span>
            <span v-else-if="master.status == 0">盘点中</span>
            <span v-else-if="master.status == 1">已暂停</span>
            <span v-else-if="master.status == 10">已完成</span>
            <span v-else>未知状态</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label-width="120px" label="盘点公司：">
            <!-- <el-cascader v-model="companyId" :options="companys" disabled /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" disabled
              check-strictly :render-after-expand="false" style="min-width: 220px;" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" label="">
            库存总数量： {{ report.allOrigin || 0 }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0" label="">
            已盘点数量：{{ report.hasTotal || 0 }} (差异数：{{ report.errOrigin - report.errTotal }})
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" label="">
            未盘点数量：{{ report.noOrigin || 0 }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
      <el-tab-pane name="current">
        <template #label>
          <span>
            <i class="el-icon-circle-check" style="color: #67c23a"></i>
            已盘点 <span v-if="hasCount > 0">{{ hasCount || 0 }}</span>
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-form-item label="关键词">
            <el-input v-model="querys.keyword" placeholder="搜索已盘点的货品" @keyup.enter="fetchs"> </el-input>
          </el-form-item>
        </el-form>

        <static-table class="table" :pager="querys" :columns="querys.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
          <template v-slot:detailImg="scope">
            <el-image style="height: 32px; width: 32px" lazy
              :src="apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0]"
              :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0],]">
              <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
            </el-image>
          </template>
          <template v-slot:checkStatus="scope">
            <!-- {{JSON.stringify(scope)}} -->
            <el-link v-if="scope.row.checkStatus == '已盘点' && scope.row.total == scope.row.origin" type="success"
              size="small" disabled>正常</el-link>
            <el-link v-else-if="scope.row.checkStatus == '已盘点' && scope.row.total != scope.row.origin" type="warning"
              size="small" disabled>异常</el-link>
            <el-link v-else type="danger" size="small" disabled>{{ scope.row.checkStatus }}</el-link>
          </template>

          <template v-slot:toolbar="scope">
            <el-link @click="openMarkEvent(scope.row)" type="primary" size="small">查看</el-link>
          </template>
        </static-table>
      </el-tab-pane>
      <el-tab-pane name="remain">
        <template #label>
          <span>
            <i class="el-icon-warning-outline"></i>
            未盘点 <span v-if="noCount > 0">{{ noCount || 0 }}</span>
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-form-item label="关键词">
            <el-input v-model="querys.keyword" placeholder="搜索未盘点的货品" @keyup.enter="fetchs"> </el-input>
          </el-form-item>
        </el-form>

        <static-table class="table" :pager="querys" :columns="querys.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler"
          :seleHandler="selectionHandler">

          <template v-slot:detailImg="scope">
            <el-image style="height: 32px; width: 32px" lazy
              :src="apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0]"
              :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode.split('-')[0],]">
              <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
            </el-image>
          </template>

          <template v-slot:checkStatus="scope">
            <!-- {{JSON.stringify(scope)}} -->
            <el-link v-if="scope.row.checkStatus == '已盘点' && scope.row.total == scope.row.origin" type="success"
              size="small" disabled>正常</el-link>
            <el-link v-else-if="scope.row.checkStatus == '已盘点' && scope.row.total != scope.row.origin" type="warning"
              size="small" disabled>异常</el-link>
            <el-link v-else type="danger" size="small" disabled>{{ scope.row.checkStatus }}</el-link>
          </template>

          <template v-slot:toolbar="scope">
            <el-link @click="openMarkEvent(scope.row)" type="primary" size="small">查看</el-link>
          </template>
        </static-table>

      </el-tab-pane>
      <!-- <el-tab-pane name="notfound">
        <template #label>
          <span>
            <i class="el-icon-circle-close" style="color: #f56c6c"></i> 未找到
            {{ querys.notfound.length || 0 }}
          </span>
        </template>
        <div v-for="v in querys.notfound" :key="v" class="text item">
          {{ "货号：" + v + "；" }}
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </el-card>

  <static-dialog ref="detailDialog" class="dialog" title="货品盘点详情" :ok="saveMarkEvent" :width="'65%'">
    <el-form :model="forms" ref="forms" :label-width="110">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="盘点库房：" prop="storeCode">
            {{ forms.storeCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌名称：" prop="brandName">
            {{ forms.brandName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌款号：" prop="brandModelCode">
            {{ forms.brandModelCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌货号：" prop="brandSerialCode">
            {{ forms.brandSerialCode }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="货品名称：" prop="detailName">
            {{ forms.detailName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统款号：" prop="detailCode">
            {{ forms.detailCode }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="系统货号：" prop="serialCode">
            {{ forms.serialCode }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="盘点情况：">
            库存数：{{ forms.origin }}，盘点数： {{ forms.total }}
            <div style="margin-left: 55px;">
              <span style="color:red;" v-if="forms.origin != forms.total">盘点异常</span>
              <span style="color:blue;" v-if="forms.origin == forms.total">盘点正常</span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="盘点人员：" prop="updateUserName">
            {{ forms.updateUserName }}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ forms.updateDate }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="18">
          <el-form-item label="盘点备注：" prop="remark">
            <el-input v-model="forms.remark" style="max-width:90%" />
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>
  </static-dialog>
</template>
<script>
import { fetchCheckDetail, saveCheckDetail, fetchCheckReport } from "@/api/stock";
import StaticTable from "@/components/static-table.vue";
import StaticDialog from "@/components/static-dialog.vue";

export default {
  name: "StockCheckDetail",
  components: { StaticTable, StaticDialog },
  data() {
    return {
      keyword: "",
      total: 1,
      apiUrl: "",
      tabName: "current",
      master: {},
      billCode: '',
      report: {},

      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "template", prop: "checkStatus", label: "状态", width: "60", },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "65", },
          { prop: "channel", label: "渠道", sortable: true, searchable: true, width: "70", showOverflowTooltip: true, },
          { prop: "detailCode", label: "款号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "template", prop: "detailImg", label: "图片", width: "55", },
          { prop: "detailName", label: "名称", sortable: true, searchable: true, width: "160", showOverflowTooltip: true },
          { prop: "serialCode", label: "货号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "brandName", label: "品牌", sortable: true, searchable: true, width: "70", showOverflowTooltip: true },
          { prop: "brandSerialCode", label: "品牌货号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "brandModelCode", label: "品牌款号", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "certNumber", label: "证书号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "weight", label: "重量", width: "50", },
          { prop: "origin", label: "系统数", width: "60", },
          { prop: "total", label: "盘点数", width: "60", },
          { prop: "unitName", label: "规格", width: "50", showOverflowTooltip: true },
          { prop: "purchasePrice", label: "采购价", width: "65", showOverflowTooltip: true },
          { prop: "productPrice", label: "入库价", width: "70", showOverflowTooltip: true },
          { prop: "updateUserName", label: "盘点人", width: "60", },
          { prop: "updateDate", label: "盘点时间", width: "145", showOverflowTooltip: true },
          { prop: "remark", label: "备注", sortable: true, searchable: true, width: "130", showOverflowTooltip: true },
          { type: "toolbar", label: "操作", width: "80" },
        ],
        sidx: "id", //默认的排序字段
        sord: "descending",
      },

      hasCount: 0,
      noCount: 0,

      forms: {},
      companys: [],
      companyId: null,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    tabChange() {
      this.querys.keyword = '';
      this.fetchs()
    },
    fetchs() {
      this.querys.filters = [];

      this.querys.filters.push({
        name: "billCode",
        value: this.billCode,
        operate: 0,
      });

      if (this.tabName == 'current') {
        this.querys.filters.push({
          name: "checkStatus",
          value: '已盘点, 已核对, 盘盈',
          operate: 6,
        });
      } else if (this.tabName == 'remain') {
        this.querys.filters.push({
          name: "checkStatus",
          value: '未盘点, 待盘点, 盘亏',
          operate: 6,
        });
      }

      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.querys,
      };

      fetchCheckDetail(search).then((res) => {
        this.querys = { ...res.data, columns: [...this.querys.columns] };

        if (this.tabName == 'current')
          this.hasCount = this.querys.records;
        else if (this.tabName == 'remain')
          this.noCount = this.querys.records;
      });

      fetchCheckReport(this.billCode).then((res) => {
        this.report = res.data.report;
        this.master = res.data.master;
        this.companyId = res.data.master.companyID.split(',');
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.querys.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.querys.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.querys.sidx = order.prop;
      this.querys.sord = order.order;
      this.fetchs();
    },
    selectionHandler(rows) {
      //this.selects = rows;
    },
    openMarkEvent(row) {
      this.forms = row;
      this.$refs.detailDialog.toggle();
    },
    saveMarkEvent() {
      let that = this;
      this.$confirm('确认修改该盘点货品的信息', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          saveCheckDetail(this.forms).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.$refs.detailDialog.toggle();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "修改失败", });
        });
    },

  },
  //在Vue的生命周期Created函数中注册相关事件
  created() {
    this.$kaung.companys().then((res) => {
      this.companys = res;
    });
  },
  mounted() {
    this.apiUrl = process.env.VUE_APP_BASEURL;

    this.billCode = this.$route.query.billCode;

    this.fetchs();
  },
  computed: {},
};
</script>